const excludeId = (data) => {
  if (data?.id) {
    const { id, ...rest } = data;
    return rest;
  }
  return data;
};

export const getFormData = (data) => {
  const attributes = data?.attributes;
  return {
    0: {
      0: {
        0: attributes?.businessLicense?.SarlNameProposition,
        1: attributes?.businessLicense?.SarlAddress,
        2: attributes?.businessLicense?.SarlDuration,
        3: attributes?.businessLicense?.SarlCapitalStructure,
        4: attributes?.businessLicense?.SarlRepresentative,
      },
      1: {
        0: attributes?.businessLicense?.nameReservation,
      },
      2: {
        0: attributes?.businessLicense?.specifications,
      },
    },
    1: {
      0: {
        0: attributes?.investmentDeclaration?.ProductOrService[0],
        1: attributes?.investmentDeclaration?.SarlAuthorization,
        2: {
          ForecastInvestment:
            attributes?.investmentDeclaration?.ForecastInvestment,
        },
        3: {
          SarlFinance: attributes?.investmentDeclaration?.SarlFinance,
          ForecastInvestment:
            attributes?.investmentDeclaration?.ForecastInvestment,
        },
        4: {
          ...attributes?.investmentDeclaration?.SarlFinancePlan,
          ForecastInvestment:
            attributes?.investmentDeclaration?.ForecastInvestment,
          SarlFinance: attributes?.investmentDeclaration?.SarlFinance,
        },
        5: attributes?.investmentDeclaration?.SarlProgramList,
      },
      1: {
        1: attributes?.investmentDeclaration?.APIIApproval,
      },
    },
    2: {
      0: {
        0: attributes?.Status?.SarlBankAccount,
        2: attributes?.Status?.iban,
      },
      1: {
        0: attributes?.Status?.SarlStatusModel,
      },
      2: {
        0: attributes?.Status?.SarlStatusValidated,
      },
    },
    3: {
      0: {
        1: attributes?.TaxRegistration,
      },
    },
    4: {
      0: {
        0: attributes?.CommerceRegistryRegistration?.EffectiveBeneficiary,
        2: attributes?.CommerceRegistryRegistration
          ?.EffectiveBeneficiaryDownload,
      },
      1: {
        1: attributes?.CommerceRegistryRegistration?.CommerceRegistryDownload,
      },
    },
    5: {
      0: {
        1: attributes?.PostCreationProcedure?.CompanyBankAccountActivated,
      },
      1: {
        0: attributes?.PostCreationProcedure?.CompanyAccountLogin,
        2: attributes?.PostCreationProcedure?.CompanyBankAccountDownload,
      },
      2: {
        0: attributes?.PostCreationProcedure?.SocialSecurityAffiliation,
        2: attributes?.PostCreationProcedure?.SocialSecurityAffiliationDownload,
      },
      3: {
        0: attributes?.PostCreationProcedure?.ProductionEntry,
        2: attributes?.PostCreationProcedure?.ProductEntityCertificate,
      },
    },
  };
};

export const getBusinessLicenseData = (data) => {
  return {
    SarlNameProposition: excludeId(data?.SarlNameProposition),
    SarlAddress: {
      ...excludeId(data?.SarlAddress ?? {}),
      addressFile: data?.SarlAddress?.addressFile?.data?.id,
    },
    SarlDuration: excludeId(data?.SarlDuration),
    SarlCapitalStructure: {
      ...excludeId(data?.SarlCapitalStructure ?? {}),
      identity: data?.SarlCapitalStructure?.identity?.data?.id,
      nationality: data?.SarlRepresentative?.nationality?.data?.id,
      residence: data?.SarlCapitalStructure?.residence?.data?.id,
    },
    SarlRepresentative: {
      ...excludeId(data?.SarlRepresentative ?? {}),
      identity: data?.SarlRepresentative?.identity?.data?.id,
      nationality: data?.SarlRepresentative?.nationality?.data?.id,
    },
    nameReservation: {
      file: data?.nameReservation?.file?.data?.id,
    },
    specifications: {
      file: data?.specifications?.file?.data?.id,
    },
  };
};

export const getCommerceRegistrationData = (data) => {
  return {
    EffectiveBeneficiary: {
      ...excludeId(data?.EffectiveBeneficiary ?? {}),
      file: data?.EffectiveBeneficiary?.file?.data?.id,
    },
  };
};

export const getInvestmentDeclarationData = (data) => {
  return {
    ForecastInvestment: excludeId(data?.ForecastInvestment),
    ProductOrService: data?.ProductOrService?.map((el) => excludeId(el)),
    SarlAuthorization: excludeId(data?.SarlAuthorization),
    SarlFinance: excludeId(data?.SarlFinance),
    SarlFinancePlan: excludeId(data?.SarlFinancePlan),
    SarlFinanceList: excludeId(data?.SarlFinanceList),
  };
};

export const getPostCreationProcedureData = (data) => {
  return {
    CompanyAccountLogin: excludeId(data?.CompanyAccountLogin),
    ProductionEntry: {
      ...excludeId(data?.ProductionEntry ?? {}),
      invoice: data?.ProductionEntry?.invoice?.data?.id,
      payment: data?.ProductionEntry?.payment?.data?.id,
    },
    SocialSecurityAffiliation: {
      ...excludeId(data?.SocialSecurityAffiliation ?? {}),
      file: data?.SocialSecurityAffiliation?.file?.data?.id,
    },
  };
};

export const getStatusData = (data) => {
  return {
    SarlStatusModel: {
      ...excludeId(data?.SarlStatusModel ?? {}),
      file: data?.SarlStatusModel?.file?.data?.id,
      filePostModification:
        data?.SarlStatusModel?.filePostModification?.data?.id,
    },
  };
};

export const saveSarlFolderInStorage = (data) => {
  localStorage.setItem('sarlFolder', JSON.stringify(data));
};

export const getSarlFolderFromStorage = () => {
  const sarlFolder = localStorage.getItem('sarlFolder');
  const parsedSarlFolder = sarlFolder && JSON.parse(sarlFolder);

  return parsedSarlFolder;
};

const getSarlPostionsFromStorage = () => {
  const savedPositions = localStorage.getItem('positions');
  const parsedSavedPositions = savedPositions ? JSON.parse(savedPositions) : {};

  return parsedSavedPositions;
};

export const saveLastFormPosition = (position) => {
  const parsedSarlFolder = getSarlFolderFromStorage();

  if (!parsedSarlFolder) return;

  const parsedSavedPositions = getSarlPostionsFromStorage();
  parsedSavedPositions[parsedSarlFolder?.id] = position;
  localStorage.setItem('positions', JSON.stringify(parsedSavedPositions));
};

export const getLastFormPosition = () => {
  const parsedSarlFolder = getSarlFolderFromStorage();
  const parsedSavedPosition = getSarlPostionsFromStorage();

  if (parsedSavedPosition[parsedSarlFolder?.id]) {
    return parsedSavedPosition[parsedSarlFolder?.id];
  }

  return {
    stageIndex: 0,
    subStageIndex: 0,
    formIndex: 0,
  };
};

// console.log(
//   forms.map((f) => {
//     return {
//       [f.name]: f.list.map((l) => {
//         return l.list.map((p) => {
//           return {
//             [p.name]: [],
//           };
//         });
//       }),
//     };
//   }),
// );
