/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';
// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
import FormGenerator from '../../../shared/UIKit/FormGenerator';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here
import { API_LOGIN, API_SARL_FOLDERS } from '../../../shared/ApIs';

// Style
import './index.scss';
// Form Schema
import schema from '../../../shared/FormSchemas/loginSchema.json';
import { saveSarlFolderInStorage } from '../../../shared/ApIs/sarlFolderUtils';
import { getSarl } from '../../../shared/ApIs/sarl';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Form() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  //  localization
  const { t } = useTranslation();
  const { title, buttonValue, linkValue } = t('form', { returnObjects: true });

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  const { language } = useI18next();

  /* ***************************** LOCAL VARIABLES **************************** */
  const errorMessages = {
    en: 'An error has occurred. Please try again later.',
    fr: "Une erreur s'est produite. Veuillez réessayer ultérieurement.",
    ar: 'حدث خطأ. الرجاء المحاولة مرة أخرى لاحقًا.',
  };
  const invalidMessages = {
    en: 'Invalid identifier or password.',
    fr: 'Identifiant ou mot de passe invalide.',
    ar: 'المعرف أو كلمة المرور غير صالحة',
  };

  /* ******************************** CALLBACKS ******************************* */
  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(`${API_LOGIN}`, {
        ...data,
      });
      const token = `Bearer ${res?.data.jwt}`;
      localStorage.setItem('token', token);

      localStorage.setItem(
        'user',
        JSON.stringify({
          id: res?.data?.user?.id,
          firstName: res?.data?.user?.firstName,
          lastName: res?.data?.user?.lastName,
          email: res?.data?.user?.email,
          phoneNumber: res?.data?.user?.phoneNumber,
        }),
      );

      const sarlResponse = await getSarl(res?.data?.user?.id);
      if (!sarlResponse) {
        navigate('/create-company');
        return;
      }

      const sarlId = sarlResponse?.id;
      const sarlFolderResponse = await axios.get(API_SARL_FOLDERS(sarlId), {
        headers: {
          Authorization: token,
        },
      });

      if (!sarlFolderResponse?.data?.data?.length > 0) {
        const newSarlFolderResponse = await axios.post(
          API_SARL_FOLDERS(),
          {
            data: {
              sarl: sarlId,
            },
          },

          {
            headers: {
              Authorization: token,
            },
          },
        );
        saveSarlFolderInStorage(newSarlFolderResponse?.data?.data);
      } else {
        saveSarlFolderInStorage(sarlFolderResponse?.data?.data[0]);
        navigate('/sarl-folder');
      }
      setResponse('SUCCESS');
    } catch (err) {
      if (
        err?.response?.data?.error?.message === 'Invalid identifier or password'
      ) {
        setResponse('INVALID');
      } else {
        setResponse('ERROR');
      }
      throw new Error('error occured');
    } finally {
      setLoading(false);
    }
  };

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="login-form">
      <div className="login-form-container">
        <Container>
          <h1 className="title">{title}</h1>
          <FormGenerator
            schema={schema}
            buttonValue={buttonValue}
            linkValue={linkValue}
            loading={loading}
            onSubmit={handleSubmit}
            link="/forget-password"
          />
          {response && (
            <div
              className={`${
                ['ERROR', 'INVALID'].includes(response) ? 'error-msg' : ''
              } ${response === 'SUCCESS' ? 'success-msg' : ''} align-c`}
            >
              {response === 'ERROR' ? errorMessages[language] : ''}
              {response === 'INVALID' ? invalidMessages[language] : ''}
            </div>
          )}
        </Container>
      </div>
    </div>
  );
}

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
